<template>
  <div class="attest">
    <back></back>
    <div class="f-23 text-center m-b-20">实名认证</div>
    <div class="text-center f-13">
      根据相关法律规定，完成身份认证才可获得数字藏品
    </div>
    <van-form @submit="onSubmit" :show-error-message="false">
      <div class="box">
        <van-field
          v-model="tel"
          name="手机号"
          placeholder="请输入手机号"
          disabled
          :rules="[{ required: true, message: '请填写手机号' }]"
        >
        </van-field>
        <van-field
          v-model="name"
          name="姓名"
          placeholder="请输入真实姓名"
          :disabled="isDisabled"
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field
          v-model="idCard"
          name="身份证号"
          placeholder="请输入身份证号"
          :disabled="isDisabled"
          :rules="[{ required: true, message: '请填写身份证号' }]"
        />
      </div>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          class="btn"
          size="large"
          :loading="isLoading"
          :disabled="isDisabled"
          >完成</van-button
        >
      </div>
    </van-form>
    <change-tel
      :show="isShowChange"
      @close="isShowChange = false"
      @success="success"
      :type="1"
    ></change-tel>
    <van-overlay
      :show="isShow"
      @click="isShow = false"
      style="background-color: rgba(0, 0, 0, 0)"
    >
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="f-18 m-b-25">确定实名认证吗？</div>
          <div class="f-15 m-b-25">
            实名认证后不可修改，请确认填写的信息无误（手机号与姓名身份证号码实名必须一致，否则无法认证成功）
          </div>
          <div class="btn-group">
            <div class="cancel" @click="isShow = false">取消</div>
            <div class="confirm" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Back from "../components/Back";
import { attestApi } from "../api/user";
import { mapState } from "vuex";
import ChangeTel from "../components/Login";
import { getWxConfig } from "../../tool";
export default {
  name: "Attest",
  components: { Back, ChangeTel },
  data() {
    return {
      name: "",
      idCard: "",
      tel: "",
      isShow: false,
      isShowChange: false,
      isLoading: false,
      isDisabled: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.tel = this.user.mobile;
    getWxConfig("轻松小镇", "实名认证");
  },
  methods: {
    onSubmit() {
      this.$dialog
        .confirm({
          title: "身份认证",
          message:
            "实名认证后不可修改，请确认填写的信息无误（手机号与姓名身份证号码实名必须一致，否则无法认证成功）",
          confirmButtonColor: "#2C2B2B",
          cancelButtonColor: "#2C2B2B",
        })
        .then(() => {
          this.confirm();
        });
    },
    success(data) {
      this.tel = data;
      this.isShowChange = false;
    },
    async confirm() {
      this.isShow = false;
      this.isLoading = true;
      try {
        const res = await attestApi({
          idCard: this.idCard,
          personName: this.name,
          mobile: this.tel,
        });
        this.$toast(res.data.info);
        this.isDisabled = true;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.$toast(e.data);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.attest {
  position: relative;
  background: url("../assets/img/rules-bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  padding-top: 45px;
  .box {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 70px 40px;
    background: url("../assets/img/user-bg2.png") no-repeat;
    background-size: 100% 100%;
    /deep/ .van-cell {
      padding: 0;
      background-color: transparent;
      margin-bottom: 25px;
      align-items: center;
      &:after {
        display: none;
      }
      .van-field__label,
      .van-field__control {
        color: black;
      }
      .van-field__control {
        color: black;
        font-weight: 500;
      }

      .van-field__value {
        background: #ffffff;
        border-radius: 18px;
        padding: 5px 15px;
      }
    }
  }
  .btn {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #ffffff;
    box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
    color: black;
    font-size: 15px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 80%;
      padding: 35px 20px 20px;
      background: #ffffff;
      box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
      border-radius: 10px;
      .btn-group {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        .cancel {
          width: 104px;
          height: 38px;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          text-align: center;
        }
        .confirm {
          width: 104px;
          height: 38px;
          background: #000000;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          color: white;
          text-align: center;
        }
      }
    }
  }
}
</style>
